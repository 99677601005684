<template lang="pug">
	div
		loading(:active.sync="busy", :is-full-page="false")
		.card.card-custom.gutter-b
			.card-header
				.card-title
					.card-label Offers
				.card-toolbar
			.card-body
				form.form.form-inline.form-filters(@submit.prevent="loadData()", :class="{expanded:filtersExpanded}")
					a.btn.btn-primary.toggle-filters.d-md-none(v-b-toggle.form-filters)
						span Filters
						i.fa.fw.fa-chevron-up.transition-all(:class="{'rotate-180':filtersExpanded}")
					b-collapse#form-filters.dont-collapse-sm(style="width: 100%;", v-model="filtersExpanded")
						.row.filters-form-row
							.form-row-main.col-auto.mr-auto(style="flex:1;")
								.form-group
									label Search
									input.form-control.input-search(type="text", placeholder="Search...", v-model="filters.keyword")
								select-network(v-model="filters.network_id")
								select-advertiser(v-model="filters.advertiser_id", :network_id="filters.network_id")
								.form-group
									label Type
									select.form-control(v-model="filters.type")
										option(value="") - All -
										option(value="offer") Normal
										option(value="asset") Asset
										option(value="custom") Custom
										option(value="targeting") Targeting
								.form-group
									label OS
									select.form-control(v-model="filters.platform")
										option(value="") - All -
										option(value="ios") iOS
										option(value="android") Android
								.form-group
									label Countries
									input.form-control.input-search(type="text", placeholder="", v-model="filters.countries")
								.form-group
									label Status
									select.form-control(v-model="filters.status")
										option(v-for="o in offerStatusOptions", :value="o.v") {{ o.t }}
								.form-group
									label VTA
									select.form-control(v-model="filters.is_vta")
										option(:value="0") All
										option(:value="1") VTA
								.form-group
									label E.Status
									select.form-control(v-model="filters.effective_status")
										option(v-for="o in offerEffectiveStatusOptions", :value="o.v") {{ o.t }}
								.form-group.no-label
									b-form-checkbox(v-model="filters.direct_only") Direct Only
								.form-group.no-label
									b-form-checkbox(v-model="filters.has_traffic") Has Traffic
								.form-group
									label URL Fields
									input.form-control.input-search(type="text", placeholder="", v-model="showFields")
								.form-group
									label af_prt
									input.form-control.input-search(type="text", placeholder="", v-model="filters.af_prt")
								.form-group
									label pid
									input.form-control.input-search(type="text", placeholder="", v-model="filters.pid")
								.form-group.no-label
									button.btn.btn-primary(type="submit") Go
							.form-row-end.col-auto
								select-advertiser(v-model="addAdvertiser", :multiple="false", @input="addOffer")
								//.form-group.no-label
									//router-link.btn.btn-success(:to="{name:'offer-add'}")
										i.flaticon2-plus
										| Add

				p.clearfix &nbsp;

		.card.card-custom.gutter-b
			.card-body
				.table-wrapper
					.tabs.table-tabs
						ul.nav.nav-tabs
							li.nav-item
								a.nav-link(:class="{active:selectedTab==='search'}", @click="selectedTab='search'") Search ({{ paginate.total }})
							li.nav-item
								a.nav-link(:class="{active:selectedTab==='bucket'}", @click="selectedTab='bucket'") Selected ({{bucket.length}})
					table.table.table-bordered.table-compact
						thead
							tr
								th
									span(v-if="selectedTab==='search'")
										a(href="javascript:void(0);", @click="selectAll()")
											i.la.la-check-square-o
										=' | '
									span
										a(href="javascript:void(0);", @click="clearBucket()")
											i.la.la-square-o
								th ID
								th Type
								th Status
								th Auto
								th Advertiser
								th Name
								th P
								th Payout
								th Model
								th Geo
								th OS
								th Caps
								th Goals
									a(href="javascript:void(0);", @click="toggleExpandColumn('goals')")
										i.la.la-plus
								th MMP
								th Redirects
								th(v-for="p in showURLFields") {{ p }}
								th(style="padding-top: 0; padding-bottom: 0; vertical-align: middle; ")
									b-dropdown(text="Bulk Actions", size="sm", variant="secondary")
										b-dropdown-item(href="#", @click="bulkConnect()") Connect
										b-dropdown-item(href="#", @click="bulkPause()") Pause
										b-dropdown-item(href="#", @click="bulkActivate()") Activate
										b-dropdown-item(href="#", @click="bulkExport()") Export
										b-dropdown-item(href="#", @click="bulkStatistics()") View statistics
										b-dropdown-item(href="#", @click="bulkModal('daily_click_cap', 'Set Click Cap')") Set Click Cap
						tbody
							tr(v-if="records.length === 0")
								td(colspan="18") No matching records were found
							tr(v-for="r in (selectedTab==='bucket' ? bucket:records)", v-bind:key="r.id", :class="getRowClass(r)")
								td.col-select(:class="{'bucket-row':bucketHash[r.id]}")
									a(href="javascript:void(0)", @click="toggleBucket(r)")
										i.la(:class="{'la-check-square':bucketHash[r.id], 'la-stop':!bucketHash[r.id]}")
								td {{ r.id }}
									br
									small.text-muted {{ r.external_id }}
								td
									span.wrap-all(:class="{'text-success':r.type==='offer' && r.is_suboffer}") {{ r.type }} ({{ r.parent_offers }})
								td.col-status
									//div.inactive-advertiser(v-if="r.advertiser_status === 0") Adv.Closed	// , v-if="r.advertiser_status"
									b-dd(:text="r.status", :class="'status-'+r.status")
										b-dropdown-item(v-for="o in offerStatusOptions", v-bind:key="o.v", :class="'option-'+o.v", @click="updateStatus(r, o.v)") {{ o.t }}
									div {{ r.effective_status }}
									//nb-select.status-selector([placeholder]="r.status", [status]="getStatusClass(r.status)",
									//style="width: 105px;", size="xsmall", customLabel="''", (selectedChange)="updateStatus(r, $event)")
										nb-option(*ngFor="let s of statusOptions", [value]="s.v") {{s.t}}
									//select.form-control([ngClass]="'mat-color-'+r.status", (change)="updateStatus(r,$event.target.value)")
										option(*ngFor="let s of statusOptions", [value]="s.v", [selected]="s.v == r.status") {{s.t}}
								td
									span(v-if="r.auto_campaign_id")
										router-link(:to="{name:'auto-campaign-edit', params: {id: r.auto_campaign_id}}") {{ r.auto_campaign_status }}
								//td
									div.app-icon-container
										a(href="javascript:void(0)", @click="selectComplex(r)")
											span.complex-badge(v-if="r.type === 'complex'") {{ r.connected_offers }}
										img(v-if="r.store_icon", :src="r.store_icon")
								td
									entity(:id="r.advertiser.id", :name="r.advertiser.name", type="advertiser")
								td.edit-inline
									quick-edit(field="name", :r="r", @on-save="updateField", :wrapped="true")
									div.category {{ r.asset_package_id }}, {{ r.category }}
									div.created_at {{ r.last_open }}
								td
									router-link(:to="{name:'placement-list', query:{offer_id: r.id}}") {{ r.num_placements }}
								td
									div(v-if="r.type === 'offer'")
										quick-edit.quick-edit-inline.edit-number(field="payout", :r="r", @on-save="updateField", :wrapped="false")
										div {{ r.currency }}
								td
									div(v-if="r.type === 'offer'")
										quick-edit-select.edit-inline.quick-edit-inline.quick-edit-payout-model(field="payout_model", :r="r", @on-save="updateField", :wrapped="true",
											:options="payoutModelOptions")
								td.edit-inline(style="max-width: 250px;")
									quick-edit(field="countries", :r="r", @on-save="updateField", :wrapped="true", type="textarea")
								td.edit-inline
									quick-edit(field="platform", :r="r", @on-save="updateField", :wrapped="true")
								td.no-wrap
									div(v-if="r.type === 'offer'")
										div Cv: {{ r.current_conversion_count.toLocaleString() }} / {{ r.daily_conversion_cap.toLocaleString() }}
										div Cl: {{ r.current_click_count.toLocaleString() }} / &nbsp;
											quick-edit.quick-edit-inline.edit-number(field="daily_click_cap", :r="r", @on-save="updateField", :wrapped="false", format="number")
											//{{ r.daily_click_cap.toLocaleString() }}
										div Im: {{ r.current_impression_count.toLocaleString() }} / &nbsp;
											//{{ r.daily_impression_cap.toLocaleString() }}
											quick-edit.quick-edit-inline.edit-number(field="daily_impression_cap", :r="r", @on-save="updateField", :wrapped="false", format="number")

										div Cv(M): {{ r.monthly_conversion_count.toLocaleString() }} / {{ r.monthly_conversion_cap.toLocaleString() }}
								td.no-wrap
									div(v-if="r.type === 'offer'")
										div C: &nbsp;
											quick-edit.quick-edit-inline.edit-number(field="daily_click_goal", :r="r", @on-save="updateField", :wrapped="false", format="number")
										div I: &nbsp;
											quick-edit.quick-edit-inline.edit-number(field="daily_impression_goal", :r="r", @on-save="updateField", :wrapped="false", format="number")
										div(v-if="expandColumn.goals")
											div(v-if="r.override_click_goal || r.override_impression_goal", style="color: #ee6622;") {{ r.override_click_goal.toLocaleString() }} / {{ r.override_impression_goal.toLocaleString() }}
											div(v-if="r.data_control_id")
												entity(type="data_control", :id="r.data_control_id", :name="r.data_control_name")
												.form-group.form-inline.inline-field
													label Sub1Mode &nbsp;
													select.form-control.form-control-sm(v-model="r._sub1_mode", @change="updateSub1Mode(r)")
														option(:value="null") None
														option(:value="'override'") Override
														option(:value="'override_empty'") Override Empty
														option(:value="'block_empty'") Block Empty
												//{{ r.daily_impression_goal.toLocaleString() }}
								td.mmp(:class="{direct:r.is_direct}") {{ r.mmp }}
									div(v-if="r.is_vta", style="color: #ee6622;") VTA
										span(v-if="r.crossplatform") &nbsp;/ CTV
									div.pid-info(v-if="r.af_pid") {{ r.af_pid }}
									div.prt-info(v-if="r.af_prt") [{{ r.af_prt }}]
									div(v-if="r.is_interceptd") INTERCEPTD
								td
									div(v-if="r.type === 'offer'")
										div(v-if="r.offerScan")
											div.last-scan {{ r.offerScan.last_scan }}
											span.scan-success(v-if="r.offerScan.status==1") {{ r.offerScan.redirects }} redirects ({{ r.scan_nondirect_jumps }})
											span.scan-failure(v-if="r.offerScan.status==2") Failed
											span.scan-error(v-if="r.offerScan.status==3", v-b-tooltip.hover title="r.offerScan.error") API Error
										a.sm-btn(href="javascript:void(0);", @click="scan(r)", v-b-tooltip.hover.bottom, title="Refresh")
											i.la.la-refresh(:class="{'la-spin':r._busy}")
										a.sm-btn(href="javascript:void(0);", @click="showOfferScanModal(r)", v-b-tooltip.hover.bottom, title="Details")
											i.la.la-search
								th(v-for="p in showURLFields")
									div(v-if="r.parsed_click_url") {{ r.parsed_click_url.params[p] }}
									div(v-if="r.parsed_impression_url") {{ r.parsed_impression_url.params[p] }}
								td.actions
									router-link.btn.btn-action.btn-secondary(:to="{name:'offer-edit', params: {id:r.id}}", v-b-tooltip.hover.bottom, title="Edit")
										i.la.la-pencil
									router-link.btn.btn-action.btn-info(:to="{name:'offer-add', query:{copy_offer_id:r.id}}", v-b-tooltip.hover.bottom, title="Duplicate Offer")
										i.la.la-copy
									router-link.btn.btn-action.btn-secondary(:to="{name:'placement-add', query:{offer_id:r.id}}", v-b-tooltip.hover.bottom, title="Create placement")
										i.la.la-plus
									//| &nbsp;
									//button.btn.btn-action.btn-secondary(@click="showOriginal(r)", v-b-tooltip.hover.bottom, title="Show Original", v-if="r.external_offer_id")
										i.la.la-code
									router-link.btn.btn-action.btn-secondary(:to="{name:'reports', query:{offer_id:r.id}}", v-b-tooltip.hover.bottom, title="Statistics")
										i.la.la-bar-chart
									button.btn.btn-action.btn-secondary(type="button", @click="autoConnect(r)", v-b-tooltip.hover.bottom, title="Auto Connect")
										i.la.la-play
									button.btn.btn-action.btn-secondary(type="button", @click="disconnectComplex(r)", v-b-tooltip.hover.bottom, title="Disconnect Complex")
										i.la.la-unlink
									button.btn.btn-action.btn-secondary(type="button", v-if="r.external_id", @click="showOriginal(r)", v-b-tooltip.hover.bottom, title="Show original")
										i.la.la-search
									button.btn.btn-action.btn-danger(type="button", @click="deleteRecord(r)")
										i.la.la-trash

					paginate(:paginator="paginate", @update-page-size="loadData")

					b-modal#original-modal(title="Original")
						pre(v-if="selectedOffer") {{ selectedOffer.original }}

</template>
<style lang="scss">
.inline-field {
	padding-top: 5px;
	label {
		font-size: 10px;
	}
	select.form-control {
		padding: 2px;
		height: 24px;
	}
}
.quick-edit-payout-model {
	&.edit-mode {
		width: 85px;
	}
}
.mmp {
	text-transform: uppercase;
	font-weight: bold;
	&.direct {
		background: #ffe2b3;
	}
	.pid-info {
		text-transform: none;
		font-weight: normal;
	}
	.prt-info {
		text-transform: none;
		font-weight: normal;
	}
}

.category {
	position: absolute;
	bottom: 2px;
	left: 2px;
	font-size: 10px;
	color: #aaa;
}

.created_at {
	position: absolute;
	bottom: 2px;
	right: 2px;
	font-size: 10px;
	color: #aaa;
}
.no-wrap {
	white-space: nowrap;
}
.col-status {
	text-align: center;
	button.btn {
		border: none;
		text-transform: uppercase;
		padding: 0 15px;
		height: 24px;
		border-radius: 4px;
	}
	.status-live {
		button.btn {
			background: #72df94;
		}
	}
}
tr.type-asset {
	background: #f6f6f6;
}
tr.row-selected {
	box-shadow: inset 0 0 0 2px rgb(255, 164, 41), inset 0 0 0 46px #ffa42930;
}
.wrap-all {
	word-break: break-all;
	max-width: 116px;
	display: inline-block;
}
</style>
<script>
import Vue from 'vue';
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import QuickConnectOffersModal from "@/views/components/QuickConnectOffersModal";

export default {
	name: 'OfferList',
	async beforeRouteEnter(to, from, next){
		next(vm => {
			vm.loadData();
			return vm;
		});
	},
	computed: {
		showURLFields(){
			let showFields = this.showFields.trim();
			if (!showFields){
				return;
			}
			return showFields.split(",").map(f => f.trim()).filter(f=>f);
		}
	},
	data(){
		return {
			busy: false,
			selectedTab: 'search',
			paginate: {
				numPages: 0,
				total: 0,
				page: 1,
				limit: 50,
				onPageChange: () => {
					this.loadData();
				}
			},
			showFields: '',
			selectedOffer: null,
			// networkOptions: [],
			addAdvertiser: null,
			expandColumn: {
				goals: false
			},
			filters: {
				af_prt: '',
				pid: '',
				keyword: '',
				countries: '',
				platform: '',
				type: '',
				status: null,
				is_vta: 0,
				effective_status: null,
				advertiser_id: [],
				network_id: 1,
				direct_only: false,
				has_traffic: false,
			},
			bucket: [],
			bucketHash: {},
			records: [],
			filtersExpanded: false,
			offerTypeOptions: Vue.$dwData.general.offerTypeOptions,
			offerEffectiveStatusOptions: [
				{v: null, t: '- All -'},
				{v: 'live', t: 'Live'},
				{v: 'paused', t: 'Paused'},
			],
			offerStatusOptions: [{
				v: null,
				t: '- All -',
			}, ...Vue.$dwData.general.offerStatusOptions.filter(o => o.v !== 'draft')],
			payoutModelOptions: Vue.$dwData.general.payoutModelOptions,
		};
	},
	mounted(){
		this.$store.dispatch(SET_BREADCRUMB, [
			{title: 'Media'},
			{title: 'Offers'}
		]);
	},
	methods: {

		toggleExpandColumn(column){
			this.expandColumn[column] = !this.expandColumn[column];
		},
		updateSub1Mode(r){
			console.log('r', r._sub1_mode);
			this.updateField(r, 'sub1_mode')
			// let resp = await this.$api.post('offers/updateField', data);
		},

		showOriginal(r){
			this.selectedOffer = r;
			this.$bvModal.show('original-modal');
		},

		getRowClass(r){
			let o = {
				['type-'+r.type]: true,
				'row-selected': this.bucketHash[r.id]
			};
			return o;
		},
		bulkStatistics() {
			let offerIds = this.bucket.map(o => {
				return o.id;
			});
			if (!offerIds.length){
				return;
			}
			this.$router.push({name: 'reports', query: {offer_id:offerIds.join(',')}});
		},
		toggleBucket(r){
			if (this.bucketHash[r.id]) {
				Vue.set(this.bucketHash, r.id, false);
				for (let i = 0; i < this.bucket.length; i++) {
					if (this.bucket[i].id === r.id) {
						this.bucket.splice(i, 1);
						return;
					}
				}
			} else {
				Vue.set(this.bucketHash, r.id, true);
				this.bucket.push(r);
			}
		},
		clearBucket(){
			this.bucket = [];
			this.bucketHash = {};
		},
		selectAll(){
			this.records.forEach(r => {
				if (!this.bucketHash[r.id]) {
					Vue.set(this.bucketHash, r.id, true);
					this.bucket.push(r);
				}
			});
		},

		bulkConnect(){
			if (this.bucket.length === 0) {
				return this.$notify.error('You must select offers');
			}
			let offerIds = this.bucket.map(o => {
				return o.id;
			});
			this.$modal.show(QuickConnectOffersModal, {
				offerIds: offerIds,
			}, {
				height: '90%'
			});
		},

		addOffer(){
			if (this.addAdvertiser && this.addAdvertiser.v) {
				let advertiserId = this.addAdvertiser.v;
				this.$router.push({name: 'offer-add', query: {advertiser_id: advertiserId}});
			}
		},

		async autoConnect(r) {
			if (this.busy) {
				return;
			}
			this.busy = true;
			try {
				let resp = await this.$api.post('/offers/autoConnect', {id: r.id});
				this.busy = false;
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		},
		async disconnectComplex(r) {
			if (this.busy) {
				return;
			}
			this.busy = true;
			try {
				let resp = await this.$api.post('/offers/autoDisconnect', {id: r.id});
				this.busy = false;
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busy = false;
			}
		},


		async updateField(r, field){
			let data = {
				id: r.id,
				field: field,
				value: r['_' + field],
			};
			// if (Vue.ovData.offer._booleans.indexOf(field) > -1) {
			// 	data.val = data.val ? 1 : 0;
			// }
			console.log(data);
			try {
				let resp = await this.$api.post('offers/updateField', data);
				r['_edit_' + field] = false;
				r[field] = resp.entity[field];
				this.$notify.success('Record has been updated');
			} catch (e) {
				console.error(e);
			}
		},

		async deleteRecord(job){
			if (!confirm('Are you sure?')){
				return;
			}
			try {
				let data = {
					id: job.id
				};
				await this.$api.post('offers/delete', data);
				this.busySave = false;
				this.$notify.success('Offer has been removed');
				this.loadData();
			} catch (e) {
				console.error(e);
				this.busySave = false;
			}
		},

		updateStatus(r, v){
			r._status = v;
			this.updateField(r, 'status');
		},

		async loadData(){
			if (this.busy){
				return;
			}
			this.busy = true;
			let params = {
				page: this.paginate.page,
				limit: this.paginate.limit,
				keyword: this.filters.keyword,
				type: this.filters.type,
				is_vta: this.filters.is_vta,
				status: this.filters.status,
				af_prt: this.filters.af_prt,
				pid: this.filters.pid,
				effective_status: this.filters.effective_status,
				platform: this.filters.platform,
				countries: this.filters.countries,
				advertiser_id: this.filters.advertiser_id.map(r => r.v).join(','),
				direct_only: this.filters.direct_only ? 1 : 0,
				has_traffic: this.filters.has_traffic ? 1 : 0,
				network_id: this.filters.network_id || 0,
			};
			try {
				let resp = await this.$api.get('/offers/getList', {params});
				this.records = resp.records;
				resp.records.forEach(r => {
					r._sub1_mode = r.sub1_mode;
					// Vue.set(r,'_sub1_mode', r.sub1_mode);
				});
				this.paginate.total = resp.total;
				this.paginate.numPages = Math.max(Math.ceil(this.paginate.total / this.paginate.limit), 1);
			} catch (e) {
				console.error(e);
			}
			this.busy = false;
		}
	}
}
</script>
